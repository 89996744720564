import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {Highlight} from "@/feature-docs/DocsWrapper.style.jsx";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    blockquote: "blockquote",
    code: "code",
    em: "em",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    hr: "hr",
    li: "li",
    ol: "ol",
    p: "p",
    pre: "pre",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      id: "introduction",
      children: _jsx(_components.a, {
        href: "#introduction",
        children: "Introduction"
      })
    }), "\n", _jsx(_components.p, {
      children: "Welcome to the wonderful world of ads!"
    }), "\n", _jsx(_components.p, {
      children: "Some quick notes before we begin!"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsxs(_components.strong, {
          children: ["Ads are a great way to ", _jsx(_components.em, {
            children: "make money"
          }), ", but also a great way to\n", _jsx(_components.em, {
            children: "lose money"
          }), "."]
        }), " Just remember to be both confident and careful,\ndon't be afraid to get a second opinion, and everything will be okay!"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Ads will break."
        }), " Sometimes it's your fault, sometimes it's the ad network's\nfault, and sometimes it's simply an act of The Great God Almighty\n(aka. Google). It's important to be able to ", _jsx(_components.em, {
          children: "identify the source of the\nproblem"
        }), " and ", _jsx(_components.em, {
          children: "fix it"
        }), " as soon as possible."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Most of ads engineering is not programming"
        }), ", but rather ", _jsx(_components.em, {
          children: "critical thinking"
        }), "\nand ", _jsx(_components.em, {
          children: "decision making"
        }), " which directly affect our revenue. And also reading\ndocumentation. Lots of documentation."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Ads are a team effort"
        }), ". You are not alone in this. We make decisions\ntogether, we solve problems together, and we celebrate together.\n", _jsx(_components.em, {
          children: "We are a team."
        })]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "With all that being said, let's get started!"
    }), "\n", _jsx(_components.h2, {
      id: "what-are-ads",
      children: _jsx(_components.a, {
        href: "#what-are-ads",
        children: "What are ads?"
      })
    }), "\n", _jsx(_components.p, {
      children: "This may seem like a very obvious question, of course they are the intrusive\nflashy boxes that no one likes, right? Well, yes, but also no. Ads are a lot\nmore than images on a screen. They are a way for us to make money, and they\nare a way for us to provide value to our users and our partners, that same\nvalue which is passed to us in the form of ad revenue."
    }), "\n", _jsx(_components.p, {
      children: "Let's take a look at a basic \"ad delivery pipeline\":"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Request"
        }), " - The user opens the app, and we send a request to the ad\nnetwork for an ad."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Response"
        }), " - The ad network responds with an ad."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Display"
        }), " - We display the ad to the user."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Our job on the frontend is to make sure that we are properly sending requests\nto the ad network and properly displaying the ads to our users."
    }), "\n", _jsx(_components.h2, {
      id: "lets-just-show-a-bunch-of-ads-and-make-lots-of-m-o-ne-y-",
      children: _jsx(_components.a, {
        href: "#lets-just-show-a-bunch-of-ads-and-make-lots-of-m-o-ne-y-",
        children: "Let's just show a bunch of ads and make lots of m o ne y !!!"
      })
    }), "\n", _jsx(_components.p, {
      children: "Well, not quite. There are a few things we need to consider before we can\nstart showing ads. Lots of technical considerations, but also some regulations\nand laws. I won't be reciting all of the laws here, but there are a few main\npoints that we need to be aware of."
    }), "\n", _jsx(_components.h3, {
      id: "gdpr",
      children: _jsx(_components.a, {
        href: "#gdpr",
        children: "GDPR"
      })
    }), "\n", _jsx(_components.p, {
      children: "GDPR is a regulation in EU law on data protection and privacy in the European\nUnion and the European Economic Area. It also addresses the transfer of\npersonal data outside the EU and EEA areas. The GDPR aims primarily to give\ncontrol to individuals over their personal data and to simplify the regulatory\nenvironment for international business by unifying the regulation within the EU."
    }), "\n", _jsx(_components.p, {
      children: "In short, we need to make sure that we are not collecting any personal data\nfrom our users without their consent. This includes things like IP addresses,\ndevice IDs, and so on. More importantly for us, we also need to make sure that\nwe are not sending personal data to our ad networks without consent."
    }), "\n", _jsx(_components.p, {
      children: "This is why we have a consent banner on our site in EU countries, managed by\nQuantcast Choice (feature-consent)."
    }), "\n", _jsx(_components.h3, {
      id: "ccpa",
      children: _jsx(_components.a, {
        href: "#ccpa",
        children: "CCPA"
      })
    }), "\n", _jsx(_components.p, {
      children: "The California Consumer Privacy Act (CCPA) is a state statute intended to\nenhance privacy rights and consumer protection for residents of California,\nUnited States."
    }), "\n", _jsx(_components.p, {
      children: "This sounds a lot like GDPR, but with one key difference: consent isn't\ndemanded by the CCPA, but rather the ability to opt-out of data collection."
    }), "\n", _jsx(_components.p, {
      children: "This means we do not need explicity consent (opt-in), we just need a way for\nusers to opt-out of data collection."
    }), "\n", _jsx(_components.h3, {
      id: "google-policy",
      children: _jsx(_components.a, {
        href: "#google-policy",
        children: "Google Policy"
      })
    }), "\n", _jsx(_components.p, {
      children: "Google is the reason for most of the visible regulatory changes to our site.\nWhile the previous data privacy regulations are managed by a 3rd party, Google\nhas additional requirements that we must follow in order to use their ad\nnetworks."
    }), "\n", _jsx(_components.p, {
      children: "One of the most important requirements is that we must have a privacy policy\non our site. This is a legal document that explains to our users what data we\ncollect and how we use it. This is a requirement for all sites that use Google\nAd Manager, and it is enforced by Google. Luckily that is basically a one-and-done\ndeal, and we don't have to worry about it too much after we have this in place."
    }), "\n", _jsx(_components.p, {
      children: "We must also distinguish the advertising content from the rest of the site.\nThis is why we have the \"Ads\" label on our ads along with the backdrop around\nthe ads that has at least a minimum of 4px margin around the ads."
    }), "\n", _jsx(_components.p, {
      children: "One that's specific for us to be able to show Google AdX ads is that we must\nmake our app look like a \"browser\" instead of an \"app\". This means that we\nmust have a URL bar, and we must have a \"back\" button. This is why we have\nthe title bar on the app (and not on web)."
    }), "\n", _jsx(_components.h2, {
      id: "so-how-do-we-do-ads",
      children: _jsx(_components.a, {
        href: "#so-how-do-we-do-ads",
        children: "So how do we do ads?"
      })
    }), "\n", _jsx(_components.p, {
      children: "In the world of ads there is no permanent solution, APIs and requirements are\nalways evolving as the industry changes.\nSo, let's explore our current implementation of ads and how it works."
    }), "\n", _jsx(_components.h3, {
      id: "register-slots",
      children: _jsx(_components.a, {
        href: "#register-slots",
        children: "Register slots"
      })
    }), "\n", _jsx(_components.p, {
      children: "Alright! So first thing we should do is request an ad, right?\nBut wait! Where will we show the ads?\nWell, first we have to register the slot, or the container where the ad\nwill be shown.\nWe do this by calling the appropriate Google Tag Manager function."
    }), "\n", _jsxs("figure", {
      children: [_jsx(_components.pre, {
        children: _jsx(_components.code, {
          className: "language-js",
          children: "// This is the name of the slot. These are defined in Google Ad Manager.\nconst slotName = \"/6355419/Travel/Europe/France/Paris\";\n\n// This is the size of the ad. For display ads we generally use 300x250.\nconst sizes = [[300, 250]];\n\n// This is the id of the element where the ad will be shown.\nconst id = \"arbitrary-unique-element-id\";\n\n// Defer the call to googletag until the library is loaded.\nreturn googleCmd(() => {\n// This returns a googletag.Slot object.\n// We can safely set and get element-persistent properties on this object,\n// as long as we do not shadow any of the properties defined by Google.\nconst slot = googletag.defineSlot(slotName, sizes, id);\nslot.addService(googletag.pubads()); // This is required to show ads\nreturn slot;\n});\n\n"
        })
      }), _jsx("figcaption", {
        children: "Ex: Registering a slot"
      })]
    }), "\n", _jsx(Highlight, {
      color: "yellow",
      children: _jsxs(_components.blockquote, {
        children: ["\n", _jsxs(_components.p, {
          children: ["It is important that all code that references ", _jsx(_components.code, {
            children: "googletag"
          }), " are wrapped\nin ", _jsx(_components.code, {
            children: "googleCmd()"
          }), ". This ensures that the code is only run after the\nGoogle Tag Manager library is loaded."]
        }), "\n"]
      })
    }), "\n", _jsxs("blockquote", {
      children: [_jsxs(_components.p, {
        children: [_jsx(_components.strong, {
          children: "Q:"
        }), " Why not use Promises instead?"]
      }), _jsx(_components.hr, {}), _jsxs(_components.p, {
        children: [_jsx(_components.strong, {
          children: "A:"
        }), " Well, we do know exactly when the library is loaded,\nbut using googleCmd is a good practice to follow as there are some cases\nwhere we want  to enqueue a function, such as our MutationObserver\nimplmentation below.\nThe benefit of using ", _jsx(_components.code, {
          children: "googleCmd"
        }), " in this case is that we do not ", _jsx(_components.em, {
          children: "wait"
        }), " for\nthe library to be loaded, we tell the library to run it when it loads.\nSo, in the case that we do not load GAM, we are never creating a dangling\nPromise."]
      })]
    }), "\n", _jsx(_components.p, {
      children: "We accomplish slot registration by attaching a MutationObserver which will\nwatch for new elements. When we find an element with an id in our master list,\nwe register the slot with the appropriate slot name and sizes."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "The master list is a map of element ids to slot configs,\nincluding the slot name and sizes."
      })
    }), "\n", _jsxs("figure", {
      children: [_jsx(_components.pre, {
        children: _jsx(_components.code, {
          className: "language-json",
          children: "{\n  \"display-rr-1\": {\n    \"slot\": \"/6355419/Travel/Europe/France/Paris\",\n    \"sizes\": [[300, 250]]\n  }\n}\n"
        })
      }), _jsx("figcaption", {
        children: "Master list structure"
      })]
    }), "\n", _jsx(_components.p, {
      children: "Using this method,\nwe will always know that a given element id will be registered with\nthe same slot name and sizes."
    }), "\n", _jsx(_components.h3, {
      id: "ad-request",
      children: _jsx(_components.a, {
        href: "#ad-request",
        children: "Ad request"
      })
    }), "\n", _jsx(_components.p, {
      children: "We use a technology called header bidding to send ad requests to multiple\nnetworks (SSPs) at the same time.\nAn SSP (Supply Side Platform) is a company that connects publishers (us) with\nadvertisers (the people who pay us money).\nOur ad networks include Google, Amazon, and Pubmatic. Each of the ad networks\nhas its own own demand (Google: AdX/Adsense, Amazon: A9, Pubmatic: OpenWrap).\nYou may see these names floating around, as well as some more SSPs that are\nmanaged by one of these networks (for example, Adagio, Appnexus, and Rubicon\nare aggregated through OpenWrap)."
    }), "\n", _jsx(_components.h4, {
      id: "kvs",
      children: _jsx(_components.a, {
        href: "#kvs",
        children: "KVs"
      })
    }), "\n", _jsx(_components.p, {
      children: "We compile our request by setting the targeting of the page or the slot."
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsx(_components.p, {
        children: "Targeting is also referred to as \"key-value pairs\", \"kvp\", or just KVs."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Targeting includes info about whether the user is on web or app,\nthe app version, which game the user is on, and so on.\nFor the most part, we use these for our internal reporting, but they are also\npassed to the ad networks so that they may target the ads to this data."
    }), "\n", _jsx(Highlight, {
      children: _jsxs(_components.blockquote, {
        children: ["\n", _jsxs(_components.p, {
          children: ["It's important that KVs are set properly. This means that they are set to\nthe correct values AND they are set at the proper time, which is ", _jsx(_components.em, {
            children: "before"
          }), " the\nad request is sent."]
        }), "\n"]
      })
    }), "\n", _jsxs("figure", {
      children: [_jsx(_components.pre, {
        children: _jsx(_components.code, {
          className: "language-json",
          children: "{\n  \"platform\": \"blitz-chrome\",\n  \"version\": \"2.1.64\",\n  \"prod\": \"0\",\n  \"display_provider\": \"blitz\",\n  \"game\": \"lol\",\n  \"route\": \"lol/tier-list\",\n  \"refresh_rate\": \"30\",\n  \"mod\": \"0\",\n  \"rr_count\": \"3\",\n  \"refresh_time\": \"30\",\n  \"refresh_count\": \"5\"\n}\n"
        })
      }), _jsx("figcaption", {
        children: "Ex: Key-value pairs"
      })]
    }), "\n", _jsxs("figure", {
      children: [_jsx(_components.pre, {
        children: _jsx(_components.code, {
          className: "language-js",
          children: "googleCmd(() => {\n  // Some KVs are page-level, which apply to all slots on the page.\n  googletag.pubads().setTargeting(\"platform\", \"blitz-chrome\");\n  googletag.pubads().setTargeting(\"version\", \"2.1.64\");\n  googletag.pubads().setTargeting(\"prod\", \"0\");\n  // Some KVs are slot-level, which apply to only one slot.\n  // Slot-level KVs override page-level KVs, since they are more specific.\n  slot.setTargeting(\"refresh_time\", \"30\");\n  slot.setTargeting(\"refresh_count\", \"5\");\n});\n"
        })
      }), _jsx("figcaption", {
        children: _jsxs(_components.p, {
          children: ["Ex: Setting KVs. ", _jsxs(_components.em, {
            children: ["Note that all Values must be ", _jsx(_components.code, {
              children: "string"
            }), "!"]
          })]
        })
      })]
    }), "\n", _jsx(_components.h4, {
      id: "outgoing-request",
      children: _jsx(_components.a, {
        href: "#outgoing-request",
        children: "Outgoing request"
      })
    }), "\n", _jsx(_components.p, {
      children: "Once the slot is registered and targeting is set,\nwe can send the request to the ad servers."
    }), "\n", _jsx(_components.p, {
      children: "We send these requests to all of our SSPs, and then we choose the best ad\n(based on the money they want to pay) from the responses we get.\nThis process of selecting the best ad is called \"auction\", and this \"bidding\"\nprocess is done by the ad server."
    }), "\n", _jsx(_components.p, {
      children: "Generally speaking, our requests follow this structure:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diff",
        children: "- Google (Ad Manager / Network)\n  - AdX/AdSense (SSP)\n  - Pubmatic (Ad Network)\n    - OpenWrap\n      - Adagio (SSP)\n      - Appnexus (SSP)\n      - Other SSPs\n  - Amazon (Ad Network)\n    - A9 (SSP)\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "In our use case, OpenWrap and all other SSPs are managed by Pubmatic,\nso we can think of it as a single request to Pubmatic.\nLikewise, A9 is managed by Amazon and AdX is managed by Google."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diff",
        children: "- Google\n  - Pubmatic\n  - Amazon\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "So, now we have a request to Google, Pubmatic, and Amazon. But we can't just\nfire all 3 requests at the same time! To understand why, we need to understand\nwhat the Pubmatic and Amazon requests actually do."
    }), "\n", _jsx(_components.p, {
      children: "When we receive a response from Pubmatic or Amazon, we are not actually\ngetting an ad at that time. Instead, we are getting a \"bid\", which is a\npromise to pay us a certain amount of money if we show their ad.\nThis is called \"header bidding\", and it is a way for us to get the best price\nfor our ad space using multiple networks. The bid info is stored in the\nKVPs of the slot for the ad server (in this case, Google Ad Manager) to\nuse when it performs the auction."
    }), "\n", _jsxs("figure", {
      children: [_jsx(_components.pre, {
        children: _jsx(_components.code, {
          className: "language-js",
          children: "function requestPubmatic(slots) {\n  return new Promise((resolve) => {\n    // removes previous bid results\n    PWT.removeKeyValuePairsFromGPTSlots(slots);\n    const pwtSlots = PWT.generateConfForGPT(slots);\n\n    PWT.requestBids(pwtSlots, function addValuesToGPT(response) {\n      // adds new bid results to the KVs\n      PWT.addKeyValuePairsToGPTSlots(response);\n      resolve(response);\n    });\n\n});\n}\n\n"
        })
      }), _jsx("figcaption", {
        children: _jsx(_components.p, {
          children: "Ex: Requesting bids from Pubmatic. We wrap it in a Promise to know\nwhen we have received a bid response."
        })
      })]
    }), "\n", _jsxs("figure", {
      children: [_jsx(_components.pre, {
        children: _jsx(_components.code, {
          className: "language-js",
          children: "function requestAmazon(slots) {\n  return new Promise((resolve) => {\n    apstag.fetchBids({ slots }, function addValuesToGPT(response) {\n      // adds new bid results to the KVs\n      apstag.setDisplayBids();\n      resolve(response);\n    });\n  });\n}\n"
        })
      }), _jsx("figcaption", {
        children: _jsx(_components.p, {
          children: "Ex: Requesting bids from Amazon. We wrap it in a Promise to know\nwhen we have received a bid response."
        })
      })]
    }), "\n", _jsx(_components.p, {
      children: "After making these requests and receiving the responses, we now have all of\nthe requirements to send the request to Google Ad Manager."
    }), "\n", _jsx(_components.p, {
      children: "When we request ads from Google, we pass in the slots that we want to refresh.\nGoogle then will run the auction and select the best ad from the bids we\nreceived from Pubmatic and Amazon, as well as their own ad network."
    }), "\n", _jsxs("figure", {
      children: [_jsx(_components.pre, {
        children: _jsx(_components.code, {
          className: "language-js",
          children: "function requestGoogle(slots) {\n  return googleCmd(() => {\n    // This function actually does A LOT!!\n    googletag.pubads().refresh(slots);\n  });\n}\n"
        })
      }), _jsx("figcaption", {
        children: _jsx(_components.p, {
          children: "Ex: Requesting ads from Google."
        })
      })]
    }), "\n", _jsxs(_components.p, {
      children: ["The pubads ", _jsx(_components.code, {
        children: "refresh()"
      }), " function does a lot more than run the auction.\nThis function from Google actually takes care of sending the request to\nGoogle Ad Manager, and then displaying the ad to the user. It is only our\nresponsibility to make sure that we have properly constructed the request,\nprior to calling this function."]
    }), "\n", _jsx(_components.p, {
      children: "Putting it all together, a single display request will look like this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "async function requestAds(slots) {\n  // note we can run Amazon and Pubmatic in parallel\n  await Promise.all(requestPubmatic(slots), requestAmazon(slots));\n  // Google MUST run last here! Once this is called, no further changes\n  // will affect the outgoing request.\n  return requestGoogle(slots);\n}\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["As noted in the comment, Google MUST run last. This is because once we call\n", _jsx(_components.code, {
        children: "googletag.pubads().refresh()"
      }), ", no further changes will affect the outgoing\nrequest. This means that if we call ", _jsx(_components.code, {
        children: "setTargeting()"
      }), " after ", _jsx(_components.code, {
        children: "refresh()"
      }), ",\nthe KVs will not be sent to the ad server."]
    }), "\n", _jsx(Highlight, {
      color: "red",
      children: _jsxs(_components.blockquote, {
        children: ["\n", _jsx(_components.p, {
          children: "Mistiming these requests can result in using stale bids, which may mean we\nare not getting the best price for our ad space, or even worse, re-use of\nbids, which will result in total loss of revenue for that ad request."
        }), "\n"]
      })
    }), "\n", _jsx(_components.p, {
      children: "Okay, so the bids were run and the ad is now shown! Great, time to swim in\nmoney, right? Well, not quite."
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "First of all, we have to make sure we are only showing ads when it would\nactually be visible to the user. This is called \"viewability\", and it is\na very important metric for advertisers."
      }), "\n", _jsxs(_components.li, {
        children: ["In order to show more than the first ads that load for the user,\nwe have to ", _jsx(_components.strong, {
          children: "refresh"
        }), " the ads. This means that we send another request to\nthe ad server to get a new ad, and then we replace the old ad with the new ad.\nWe do this periodically to ensure that we are generating revenue throughout\nthe entirety user's session, not just the start."]
      }), "\n", _jsx(_components.li, {
        children: "We also have to make sure that we are collecting data about the ad impressions\nso we can further optimize our ad stack."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.em, {
        children: "To be continued..."
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
